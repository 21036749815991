(function () {
    "use strict";
    $(document).ready(function () {
        console.log("homepage.js");

        var homepageMainSlider = new Swiper('.main-slider', {
            pagination: {
                el: '.swiper-pagination',
                type: 'fraction',
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        });

        $(".specializations-links li").hover(
            function () {
                var itemIndex = $(this).index();
                $('.col-specializations-images .image').eq(itemIndex).fadeIn();
                $('.col-specializations-images .image').eq(itemIndex).siblings().fadeOut();
            }
        );

        $('.col-blogs-images .row').eq(0).siblings().hide();

        $(".blogs-links li").on('click',
            function () {
                var catIndex = $(this).index();
                $('.col-blogs-images .row').eq(catIndex).siblings().stop().fadeOut(function () {
                    $('.col-blogs-images .row').eq(catIndex).stop().fadeIn();
                });
            }
        );

        var $wpURL = "/wp-json/wp/v2/posts?per_page=4";

        var postsPerBlogForAllContainer = 1;
        var allPosts = $('.source-blog').length * postsPerBlogForAllContainer;

        $('.source-blog').each(function () {
            var blogUrl = $(this).data('blogurl') + $wpURL;
            var blogId = $(this).data('blogid');
            var isLast = $(this).data('islast');

            $.ajax({
                type: 'GET',
                url: blogUrl,
                success: function (data, textStatus, request) {
                    var counter = 0;
                    $.each(data, function (index, value) {
                        var postHtml = buildPost(value);
                        $('.blog-posts-container[data-blogid="' + blogId + '"]').append(postHtml);
                        if (counter++ < postsPerBlogForAllContainer) {
                            $('.blog-posts-container-all').append(postHtml);
                        }
                    });

                    //sort the blogs, if right amount of posts is insterted (2 posts
                    if($(".blog-posts-container-all .col-single-blog").length === allPosts) {
                        var sortResults = $(".blog-posts-container-all .col-single-blog").sort(function (a, b) {
                            return new Date($(a).attr("data-date")) < new Date($(b).attr("data-date")) ? 1 : -1;
                        });

                        $(".blog-posts-container-all").html(sortResults);
                    }
                },
                error: function (request, textStatus, errorThrown) {
                }
            });
        });

        $(".blogs-links a[href='#']").click(function (e) {
            e.preventDefault();
        });

        function buildPost(post) {
            var postHtml =
                "<div class='col-12 col-md-6 col-single-blog' data-date='" + post.date + "'>" +
                "   <a href='" + post.link + "' target='_blank'>" +
                "       <div class='image' style='background-image: url(" + post.image + ");'>" +
                "       </div>" +
                "   </a>" +
                "   <div class='text'>" +
                "       <a href='" + post.link + "' target='_blank'>" +
                "           <h3 class='title'>" + post.title.rendered + "</h3>" +
                "       </a>" +
                "       <div class='meta'>" +
                "          <span class='date'>" + post.formatted_date + "</span>" +
                "          <span class='author'>" + post.post_author + "</span>" +
                "       </div>" +
                "       <p> " +
                "           Tematy: " + post.categories +
                "       </p>" +
                "   </div>" +
                "</div>";

            return postHtml;
        }
    });
})(jQuery);
