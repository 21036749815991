(function () {
  'use strict';
  $(document).ready(function () {
    console.log('subpage.js');

    // responsive image enlarging
    $('.popup-trigger').click(function (e) {
      $(this).parent().find('.popup-image-container').fadeIn();
      e.preventDefault();
      return false;
    });

    $('.popup-image-container').click(function () {
      $(this).fadeOut();
    });
    
  });
}(jQuery));
