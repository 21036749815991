(function () {
  'use strict';
  $(document).ready(function () {
    console.log('main.js');

    var lastScrollTop = 0;
    $(window).scroll(function(event) {
      var st = $(this).scrollTop();
      if (st > 0) {
        $('.header').addClass('scroll-bg');
      } else {
        $('.header').removeClass('scroll-bg');
      }
      lastScrollTop = st;
    });

    //Menu mobile toggle
    $(".menu-toggle").click(function(){
      $('body').toggleClass("no-scroll");

      $(this).toggleClass("open");
      if ( ($(".header").hasClass("open")) && ($(".menumain").is(":hidden")) ) {
        $('.menumain').fadeIn({
          start: function () {
            $(this).css({
              display: "flex"
            })
          }
        });
      } else {
        $(".header").toggleClass("open");
        $('.menumain').fadeToggle({
          start: function () {
            $(this).css({
              display: "flex"
            })
          }
        });
      }

    });

    //Linki posiadające podmenu - wyłączenie domyślnego zachowania

    $("#brysiewicz-menu li.menu-item-has-children > a").click(function (e) {
      e.preventDefault();
    });

    $("#brysiewicz-menu li.menu-item-has-children > ul," +
      "#brysiewicz-menu li.menu-item-has-children > ul > li").click(function (e) {
      e.stopPropagation();
    });

    //Mainmenu - level 0

    $("#brysiewicz-menu > li.menu-item-has-children").click(function () {
      if (window.matchMedia("(min-width: 1025px)").matches) {
        $(".header").toggleClass("open");
      }
      $(this).toggleClass('open');

      if (window.matchMedia("(min-width: 1025px)").matches) {

        $(this).children('ul').fadeToggle({
          start: function () {
            $(this).css({
              display: "flex"
            })
          }
        });
        $(this).siblings('li').removeClass('open');
        $(this).siblings('li').children('ul').fadeOut();

      } else {

        $(this).children('ul').slideToggle({
          start: function () {
            $(this).css({
              display: "flex"
            })
          }
        });
        $(this).siblings('li').removeClass('open');
        $(this).siblings('li').children('ul').slideUp();

      }

    });

    $(document).click(function(e) {
      if (!$(e.target).is('form.main-search, form.main-search *')) {
        $("form.main-search").fadeOut();
      }
    });

    //Search trigger
    $('#search-trigger').click(function () {
      $('form.main-search').fadeToggle('active');
      return false;
    });

    // Sharing on social media
      $(".share").jsSocials({
          showLabel: false,
          shareIn: "popup",
          shares: [{
              share: "facebook",
              logo: "/wp-content/themes/wp-bootstrap-gulp/dist/images/fb-logo.png"
          }, {
              share: "twitter",
              logo: "/wp-content/themes/wp-bootstrap-gulp/dist/images/tw-logo.png"
          }, {
              share: "linkedin",
              logo: "/wp-content/themes/wp-bootstrap-gulp/dist/images/ln-logo.png"
          }]
      });

  });
}(jQuery));
